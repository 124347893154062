import React from 'react';
import "./RecyclingRules.css";
import AutoIcon from "../controls/AutoIcon.mjs";

const RecyclingRules = class extends React.Component {
	render() {
		return(
			<div className={"RecyclingRules"}>
				<div>
					La raccolta avviene nelle prime ore della giornata, in genere tra le 4 e le 5.<br />
					L'azienda richiede che i contenitori dei rifiuti vengano esposti per la raccolta prima delle 2,
					ovvero <strong>la sera precedente</strong>.
				</div>
				<h4>
					<AutoIcon value={"trash"}/>
					Plastica e metallo
				</h4>
				<p>
					Generalmente raccolti il martedì
				</p>
				<p>
					Nel contenitore della plastica e metallo il rifiuto va conferito appiattito (quando possibile) e con
					qualsiasi sacchetto in plastica
				</p>
				<ul className={'ok'}>
					<li>Bombolette spray che non contengono materiale infiammabile e/o pericoloso,</li>
					<li>Caffettiere</li>
					<li>Contenitori in banda stagnata (scatole di pelati, legumi, tonno, cibi per animali)</li>
					<li>Fogli in alluminio</li>
					<li>Lattine per bibite</li>
					<li>Teglie in alluminio</li>
					<li>Bottiglie in plastica per bevande</li>
					<li>Contenitori per alimenti</li>
					<li>Flaconi per detersivi e prodotti per l’igiene della casa e della persona</li>
					<li>Grucce per abiti</li>
					<li>Imballaggi in genere con i simboli PE – PET – PP – PVC – PS</li>
					<li>Pellicole</li>
					<li>Piatti</li>
					<li>Stoviglie e bicchieri in plastica usa e getta (anche sporchi)</li>
					<li>Tubetti del dentifricio</li>
					<li>Vaschette in plastica e alluminio</li>
					<li>Vaschette portauova (se in plastica)</li>
					<li>Vasi in plastica da vivaio</li>
					<li>Polistirolo.</li>
				</ul>
				<ul className={'ko'}>
					<li>Contenitori Tetra Pak</li>
					<li>Gomma</li>
					<li>Nylon</li>
					<li>Bombolette spray e tutti i contenitori contenenti gas dannosi per l’uomo e l’ambiente</li>
					<li>Contenitori di colle e solventi</li>
				</ul>
				<h4>
					<AutoIcon value={"trash"}/>
					Vetro
				</h4>
				<p>
					Generalmente raccolto ogni due mercoledì
				</p>
				<p>
					Nel contenitore del vetro il rifiuto va conferito senza sacchetto
				</p>
				<ul className={'ok'}>
					<li>Barattoli</li>
					<li>Bicchieri</li>
					<li>Bottiglie per bevande</li>
					<li>Caraffe</li>
					<li>Contenitori per liquidi in genere</li>
					<li>Fiale in vetro</li>
					<li>Flaconi</li>
					<li>Portafoto (se in vetro)</li>
					<li>Vasetti.</li>
				</ul>
				<ul className={'ko'}>
					<li>Ceramica</li>
					<li>Cristallo</li>
					<li>Farmaci e prodotti chimici</li>
					<li>Lampadine e tubi al neon</li>
					<li>Specchi.</li>
				</ul>
				<h4>
					<AutoIcon value={"trash"}/>
					Carta e cartone
				</h4>
				<p>
					Generalmente raccolti il giovedì
				</p>
				<p>
					Nel contenitore della carta e cartone il rifiuto va conferito appiattito e senza sacchetto o con
					busta in carta
				</p>
				<ul className={'ok'}>
					<li>Biglietti</li>
					<li>Buste</li>
					<li>Calendari</li>
					<li>Carta da regalo</li>
					<li>Cartoni della pizza</li>
					<li>Confezioni in cartoncino</li>
					<li>Contenitori Tetra Pak (latte, succhi di frutta ecc.)</li>
					<li>Giornali</li>
					<li>Imballaggi in cartone ondulato</li>
					<li>Libri</li>
					<li>Poster</li>
					<li>Sacchetti di carta</li>
					<li>Scatole</li>
					<li>Pacchetti</li>
					<li>Quaderni.</li>
				</ul>
				<ul className={'ko'}>
					<li>Carta chimica (fax) o copiativa</li>
					<li>Carta oleata</li>
					<li>Stagnola o plastificata</li>
					<li>Copertine plastificate</li>
					<li>Scontrini.</li>
				</ul>
				<h4>
					<AutoIcon value={"trash"}/>
					Organico
				</h4>
				<p>
					Generalmente raccolto il lunedì, il mercoledì e il sabato
				</p>
				<p>
					Nel contenitore dell’organico il rifiuto va conferito con il sacchetto biodegradabile
				</p>
				<ul className={'ok'}>
					<li>Alimenti avariati o scaduti</li>
					<li>Avanzi di cibo in genere</li>
					<li>Camomilla e tisane</li>
					<li>Fiori recisi</li>
					<li>Cenere spenta</li>
					<li>Fibre naturali (cotone, canapa, lino)</li>
					<li>Fondi di caffè e filtri di tè</li>
					<li>Paglia</li>
					<li>Piante domestiche e piccole potature in genere (max 3 sacchi da 15 kg cad.)</li>
					<li>Piccole lettiere di animali domestici se in materiale organico</li>
					<li>Tappi in sughero</li>
					<li>Piccoli scarti in legno non trattato</li>
					<li>Piccoli tessuti in fibre naturali (cotone e lino)</li>
					<li>Stuzzicadenti.</li>
				</ul>
				<ul className={'ko'}>
					<li>Tutto ciò che non è biodegradabile.</li>
				</ul>
				<h4>
					<AutoIcon value={"trash"}/>
					Secco residuo e pannolini
				</h4>
				<p>
					Generalmente raccolti il venerdì
				</p>
				<p>
					Nel contenitore del secco residuo il rifiuto va conferito con qualsiasi tipo di sacchetto <u>(NO
					SACCO NERO)</u>
				</p>
				<ul className={'ok'}>
					<li>Mascherine</li>
					<li>Guanti</li>
					<li>Accendini</li>
					<li>Bigiotteria</li>
					<li>Candele</li>
					<li>Capelli</li>
					<li>Cards plastificate (bancomat, spesa ecc.)</li>
					<li>Carta per affettati/adesiva/carbone/plastificata/vetrata</li>
					<li>CD</li>
					<li>Cerotti</li>
					<li>Cialde in plastica per caffè espresso</li>
					<li>Cicche di sigarette</li>
					<li>Collant</li>
					<li>Colori a olio/acrilici</li>
					<li>Cosmetici</li>
					<li>Cotone idrofilo</li>
					<li>Cotton-fioc</li>
					<li>Cover di cellulari</li>
					<li>Creta</li>
					<li>Crosta in cera dei formaggi</li>
					<li>Cuoio</li>
					<li>Elastici</li>
					<li>Evidenziatori, penne, pennarelli, matite</li>
					<li>Fiori finti</li>
					<li>Forbici</li>
					<li>Fotografie</li>
					<li>Garze</li>
					<li>Giocattoli non elettrici</li>
					<li>Gomma, gomma da masticare, gommapiuma</li>
					<li>Lamette</li>
					<li>Nastri per regali</li>
					<li>Occhiali</li>
					<li>Ombrelli</li>
					<li>Orologi</li>
					<li>Palloni da gioco</li>
					<li>Pannolini e pannoloni</li>
					<li>Pellicole fotografiche</li>
					<li>Peluche</li>
					<li>Piastrine anti zanzare</li>
					<li>Piccole ceramiche</li>
					<li>Piccoli oggetti in legno verniciato</li>
					<li>Polvere dei pavimenti</li>
					<li>Pongo</li>
					<li>Profilattici</li>
					<li>Radiografie</li>
					<li>Sacchetti dell’aspirapolvere</li>
					<li>Salviette umidificate</li>
					<li>Saponette</li>
					<li>Scontrini</li>
					<li>Spazzole, spazzolini da denti</li>
					<li>Stracci</li>
					<li>Tempera</li>
					<li>Videocassette</li>
					<li>Zerbini.</li>
				</ul>
				<ul className={'ko'}>
					<li>Tutto ciò che è riciclabile.</li>
				</ul>
			</div>
		)
	}
}

export default RecyclingRules;